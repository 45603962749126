<template>
  <div class='chartWrap_mobile'>
    <div class="multi_modal_img" style="position: relative;" v-if="isCogvlmModel">
      <img v-if="imageUrl" class="temp_img" :src="imageUrl">
      <el-upload action="" drag :auto-upload="true" :show-file-list="false" :before-upload="handleBeforeUpload"
        :multiple="false" :limit="1" accept="image/x-png,image/jpeg" :style="{
          position: 'absolute', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%', 
          opacity: imageUrl ? 0 : 1
        }">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <div class="line" v-if="isCogvlmModel"></div>
    <div class="chat_content" ref="srcollRef" :class="{'isCogvlmModel':isCogvlmModel}">
      <div class="chatRecords">
        <div v-for="(item,index) in chatRecords" :key="index">
          <div class="person_msg" v-if="item.type == 1">
            <div class="word">{{ item.word }}</div>
          </div>
          <div class="ai_msg" v-if="item.type == 2">
            <div class="avatar_wrap">
              <img :src="info.pictureUrl" alt="" />
              <span>{{info.name}}</span>
            </div>
            <div class="word" v-if="item.word">
              <!-- <span id="response_row" class="result-streaming" v-if="item.requestFlag"></span> -->
              <!-- <span style="white-space:pre-wrap" v-html="item.word"></span> -->
              <my-md-preview :text="item.word" theme="light" show-code-row-number
                preview-theme="default"></my-md-preview>
              <div @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                <i v-if="!copied&&!loading" :title="showTooltip ? '复制' : ''" @click="copyToClipboard(item.word)"
                  class="el-icon-copy-document copy-button"></i>
                <i v-if="copied" :title="showTooltip ? '复制' : ''" class="el-icon-check copy-button"></i>
              </div>
            </div>

            <template v-if="item.img">
              <el-image :src="`data:image/jpeg;base64,${item.img}`"
                :preview-src-list="[`data:image/jpeg;base64,${item.img}`]">
              </el-image>
            </template>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!(isCogvlmModel&&!imageUrl)">
      <div class="ask-input1">
        <div class="loading" v-show="loading"></div>
        <textarea v-model="question" @keyup.enter="sendMessage" :placeholder="placeholderText" ref="textarea"
          class="chart-textarea" @input="adjustHeight">
        </textarea>
        <el-button @click="sendMessage" class="chart-btn22" :disabled="loading" v-show="!showStopBtn">
          <i class="el-icon-top"></i>
        </el-button>
        <el-button v-show="showStopBtn" @click="stopAnswers" class="chart-btn22">
          <!-- <i class="el-icon-video-pause"></i> -->
          <img src="@/assets/images/stop.svg" alt="">
        </el-button>
      </div>

    </template>
  </div>
</template>

<script>
import file2base64 from '@/utils/file2base64'
import { v4 as uuidv4 } from 'uuid';
import api from '@/api/api'
import pathMixin from './proxyPathMixin'
import { fetchEventSource } from '@microsoft/fetch-event-source';
export default {
  mixins: [pathMixin],
  props: {
    info: {
      type: Object,
      default: () => { }
    },
    id: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      imageUrl: '',
      historyChat: [],
      chatRecords: [
      ],
      loading: false,
      question: '',

      response: '',

      completeText: '',
      showStopBtn: false,
      abortController: null,
      originalBodyWidth: '',
      originalViewportContent: null,
      copied: false,
      showTooltip: false,  // 控制提示显示与否
    }
  },
  computed: {
    isCogvlmModel () {
      return this.info?.sceneTagNames?.includes('图像描述');
    },
    isStableDiffModel () {
      return this.info?.sceneTagNames?.includes('文本生成图片');
    },
    placeholderText () {
      if (this.isCogvlmModel) {
        return '请对图片问一个问题吧';
      } else if (this.isStableDiffModel) {
        return '请输入一段描述，即可根据描述生成一张图片哦';
      } else {
        return '您好，请输入';
      }
    }
  },
  watch: {
    response (newVal) {
      let record = { type: 2 };
      if (this.isStableDiffModel) {
        record.img = newVal;
      } else {
        record.word = newVal;
      }
      this.chatRecords.push(record);

      this.$nextTick(() => {
        const chatContent = this.$refs.srcollRef;
        if (chatContent) {
          chatContent.scrollTop = 9999999999;
        }
      });
    },
    id: {
      handler () {
        this.historyChat = [];
        this.imageUrl = '';
        this.chatRecords = []
      },
      immediate: true
    },
  },
  mounted () {
    this.modifyBodyStyles();
  },
  beforeDestroy () {
    this.restoreBodyStyles();
  },
  methods: {
    async copyToClipboard (mdContent) {
      try {
        const cleanedContent = mdContent.replace(/<blockquote[\s\S]*?<\/blockquote>/g, '');
        await navigator.clipboard.writeText(cleanedContent);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 1500);
      } catch (err) {
        console.error("复制失败", err);
      }
    },
    modifyBodyStyles () {
      this.originalBodyWidth = document.body.style.width;
      document.body.style.width = '100vw';
      document.body.classList.add('mobile_device');
      let viewportMeta = document.querySelector('meta[name="viewport"]');
      const contentValue = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";

      if (viewportMeta) {
        // 如果已经存在meta viewport标签，保存其原始内容并修改之
        this.originalViewportContent = viewportMeta.getAttribute('content');
        viewportMeta.setAttribute('content', contentValue);
      } else {
        // 如果不存在，则创建一个新的meta viewport标签并添加到head中
        viewportMeta = document.createElement('meta');
        viewportMeta.name = "viewport";
        viewportMeta.content = contentValue;
        document.head.appendChild(viewportMeta);
      }
    },
    restoreBodyStyles () {
      document.body.style.width = this.originalBodyWidth;
      document.body.classList.remove('mobile_device');
      let viewportMeta = document.querySelector('meta[name="viewport"]');
      if (this.originalViewportContent !== null && viewportMeta) {
        viewportMeta.setAttribute('content', this.originalViewportContent);
      } else if (!this.originalViewportContent && viewportMeta) {
        // 如果之前没有viewport标签，则移除新添加的
        document.head.removeChild(viewportMeta);
      }
    },
    handleBeforeUpload (file) {
      this.historyChat = [];
      this.chatRecords = []
      file2base64(file).then(res => {
        this.imageUrl = res;
      }, err => {
        if (err === 'TYPE_INVALID') {
          this.$message.error('图片转base64失败');
        }
      });
      return false;
    },
    async sendMessage () {
      if (this.loading) return false;
      if (this.question.trim().length == 0) {
        return this.$message.warning("请输入问题");
      } else {
        this.chatRecords.push({
          type: 1,
          word: this.question,
        });
        let tempValue = this.question;
        this.question = "";
        this.$nextTick(() => {
          const chatContent = this.$refs.srcollRef;
          if (chatContent) {
            chatContent.scrollTop = chatContent.scrollHeight;
          }
        });
        this.loading = true;
        // 初始化请求数据的实例对象
        // let instance = {
        //   text_chat: tempValue,
        //   history1: this.historyChat
        // };
        // if (this.isCogvlmModel) {
        //   instance.image_b64 = this.urlSateBase64Encode(this.imageUrl.match(/base64,(\S*)/)[1]);
        // }
        // if (!this.isStableDiffModel) {
        //   instance.stream = true;
        // }
        // const requestData = {
        //   instances: [instance]
        // };
        const url = this.info?.appDetailAddVo?.url;
        if (!url) {
          this.$message.error('URL不存在');
          return;
        }
        const apiTestUrl = this.extractProxyPath(url);
        if (!apiTestUrl) {
          this.$message.error('无法提取URL中的/proxy/部分');
          return;
        }
        const recordUrl = this.transformUrl(url);
        //api.testRecord(recordUrl); // 日志记录

        if (this.isStableDiffModel) {
          const Text2ImageParams = {
            prompt: tempValue,
            negative_prompt: "",
            steps: 10
          }
          try {
            const res = await api.modelApiTest(apiTestUrl, Text2ImageParams)
            // this.handleResponse(res)
            this.response = res.result_img
          } catch (error) {
          } finally {
            this.loading = false;
            this.tempValue = ''
          }
        }
        else if (this.isCogvlmModel) {
          const Image2TextParams = {
            instances: [{
              text_chat: tempValue,
              history1: this.history,
              image_b64: this.urlSateBase64Encode(this.imageUrl.match(/base64,(\S*)/)[1])
            }]
          }
          this.fetchForImage2Text(apiTestUrl, Image2TextParams)
        }
        else {
          // 获取最新的 3 轮对话，即最后 6 条记录
          const latestRecords = this.chatRecords.slice(-3 * 2).map(record => {
            let role = record.type === 1 ? 'user' : 'assistant';
            return {
              role: role,
              content: record.word
            };
          });

          const TextGenerationParams = {
            model: 'msx-llm',
            messages: latestRecords,
            stream: true
            // max_tokens: 500,
          }
          this.fetchForTextGeneration(apiTestUrl, TextGenerationParams)
        }
      }
    },
    // handleResponse (res) {
    //   if (!this.isStableDiffModel) {
    //     this.historyChat = res.history || [];
    //     this.response = res.result_data;
    //   } else {
    //     this.response = res.result_img
    //   }
    // },
    urlSateBase64Encode (base64Str) {
      if (!base64Str) return;
      let safeStr = base64Str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=/g, '');
      // const UriSafe = (src: string) => src.replace(/[+\/]/g, (m0) => m0 == '+' ? '-' : '_').replace(/=+$/m, ‘');
      return safeStr;
    },
    fetchForImage2Text (apiTestUrl, requestData) {
      requestData.instances[0].stream = true;
      this.abortController = new AbortController();
      console.log(apiTestUrl, requestData)
      const headers = new Headers({});
      const body = JSON.stringify(
        requestData
      );
      const index = this.chatRecords.length;
      this.loading = true;
      this.showStopBtn = true;
      let tempData = ''
      const eventSource = fetchEventSource(`/apitest${apiTestUrl}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body,
        signal: this.abortController.signal,
        openWhenHidden: true,
        onmessage: (e) => {
          if ((e.data.startsWith('[(') || e.data.startsWith('[[') || e.data.startsWith("[{'role'") || e.data.startsWith('[{"role"')) && e.data.length > 15) {
            let temp = e.data
            const jsonCompatibleString = temp.replace(/'/g, '"').replace(/\(/g, '[').replace(/\)/g, ']');
            try {
              const jsonArray = JSON.parse(jsonCompatibleString);
              this.historyChat = jsonArray;
            } catch (error) {
              console.log(error, "解析失败.....")
            }
            this.loading = false;
            this.showStopBtn = false;
            this.abortController.abort();
            eventSource.close();
          } else {
            let message = e.data;
            message = message.replaceAll("<|im_end|>", "").replace(/\\n/g, '\n')
              .replace(/\\r/g, '\r')
              .replace(/\\t/g, '\t')
              .replace(/\\f/g, '\f')
              .replace(/\\v/g, '\v')
              .replace(/\\\\/g, '\\')
            tempData += message;
            this.chatRecords.splice(index, 1, {
              type: 2,
              word: tempData,
            });
            const chatContent = this.$refs.srcollRef;
            if (chatContent) {
              chatContent.scrollTop = 9999999999;
            }
          }
        },
        onclose: () => {
          console.log("close");
          this.loading = false;
          this.showStopBtn = false;
          this.abortController.abort();
          eventSource.close();
        },
        onerror: (error) => {
          console.log("error", error);
          this.$message.error('服务器响应失败')
          let index = this.chatRecords.length;
          this.chatRecords.splice(index, 1);
          this.loading = false;
          this.showStopBtn = false;
          this.abortController.abort();
          eventSource.close();
        },
      });
    },
    fetchForTextGeneration (apiTestUrl, requestData) {
      this.abortController = new AbortController();
      const body = JSON.stringify(
        requestData
      );
      const index = this.chatRecords.length;
      this.loading = true;
      this.showStopBtn = true;
      let tempData = ''
      const eventSource = fetchEventSource(`/apitest${apiTestUrl}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body,
        signal: this.abortController.signal,
        openWhenHidden: true,
        onmessage: (e) => {
          if (e.data == "[DONE]") {
            this.loading = false;
            this.showStopBtn = false;
            this.abortController.abort();
            eventSource.close();
          } else {
            let answer = JSON.parse(e.data);
            console.log(answer, 'answeranswer')
            let thinkContent = answer.choices[0].delta.content;
            if (thinkContent.includes('<think>')) {
              thinkContent = '<blockquote>'
            }
            if (thinkContent.includes('</think>')) {
              thinkContent = '</blockquote>'
            }
            if (thinkContent == '\n\n') {
              thinkContent = ''
            }
            tempData += thinkContent;
            this.chatRecords.splice(index, 1, {
              type: 2,
              word: tempData,
            });
            const chatContent = this.$refs.srcollRef;
            if (chatContent) {
              chatContent.scrollTop = 9999999999;
            }
          }
        },
        onclose: () => {
          console.log("close");
          this.loading = false;
          this.showStopBtn = false;
          this.abortController.abort();
          eventSource.close();
        },
        onerror: (error) => {
          console.log("error", error);
          this.$message.error('服务器响应失败')
          let index = this.chatRecords.length;
          this.chatRecords.splice(index, 1);
          this.loading = false;
          this.showStopBtn = false;
          this.abortController.abort();
          eventSource.close();
        },
      });
    },
    stopAnswers () {
      this.abortController.abort();
      this.loading = false;
      this.showStopBtn = false;
    },
    adjustHeight () {
      const textarea = this.$refs.textarea;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    },
  },


}
</script>

<style lang="scss" scoped>
::v-deep.chartWrap_mobile {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  font-size: 10vw;
  box-sizing: border-box;
  position: relative;
  background: #f5f5f5;
  border-radius: 2vw;
  .multi_modal_img {
    width: 100%;
    height: 200px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-upload {
      height: 180px;
      .el-upload-dragger {
        height: 180px;
      }
    }
    .temp_img {
      max-width: 250px;
      max-height: 170px;
      margin-bottom: 10px;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .line {
    width: 80vw;
    border: 1vw solid rgb(230, 227, 227);
    margin: 0.5vw auto;
  }
  .chat_content {
    scroll-behavior: smooth;
    height: calc(100% - 37vw) !important;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 5vw;
    padding-bottom: 5vw !important;
  }
  .isCogvlmModel {
    height: calc(100% - 320px) !important;
  }
  .chat_content::-webkit-scrollbar {
    width: 8px; /* 滚动条的宽度 */
    background-color: #f9f9f9; /* 滚动条的背景色 */
  }
  .chat_content::-webkit-scrollbar-thumb {
    background-color: #b3b3b3; /* 滚动条滑块的颜色 */
    border-radius: 4px; /* 滚动条滑块的圆角 */
  }
  .chat_content::-webkit-scrollbar-track {
    background-color: #e1e1e1; /* 滚动条滑道的颜色 */
    border-radius: 4px; /* 滚动条滑道的圆角 */
  }
  .chatRecords {
    > div {
      margin: 3vw 0px;
    }
    .ai_msg {
      background: #f5f5f5;
      border-radius: 1vw;
      text-align: left;
      width: 100%;
      .avatar_wrap {
        display: flex;
        align-items: center;
        img {
          width: 8vw;
          height: 8vw;
        }
        span {
          font-size: 3vw;
          font-family: Source Han Sans CN, Source Han Sans CN-Medium;
          font-weight: 500;
          text-align: left;
          color: #333333;
          margin-left: 1vw;
        }
      }
      .word {
        padding: 1vw 0vw !important;
        box-sizing: border-box;
        max-width: 100%;
        line-height: 5vw;
        font-size: 3vw;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: rgb(63, 74, 84);
        display: inline-block;
        text-align: left !important;
        word-break: break-all;
        overflow-wrap: break-word;
        .copy-button {
          font-size: 4vw;
          margin-top: 2vw;
          cursor: pointer;
        }
        .copy-button:hover {
          background-color: #f5f5f5;
        }
      }
      .el-image {
        width: 8vw;
        margin-left: 4vw;
      }
    }
    .person_msg {
      text-align: right;
      .word {
        box-sizing: border-box;
        padding: 1vw 2vw !important;
        line-height: 5vw;
        background: #fff;
        font-size: 3vw;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: rgb(63, 74, 84);
        display: inline-block;
        text-align: left !important;
        word-break: break-all;
        overflow-wrap: break-word;
      }
    }
  }
  .ask-input1 {
    bottom: 5vw;
    position: absolute;
    width: 90vw;
    background: #ffffff;
    border-radius: 2vw;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    padding: 1vw;

    .chart-textarea {
      flex: 1;
      background: #ffffff;
      border-radius: 1vw;
      border: none;
      box-sizing: border-box;
      resize: none;
      font-size: 3.2vw;
      line-height: 1.5;
      outline: none;
      min-height: 5vw !important;
      max-height: 25vw !important;
      overflow-y: auto;
    }
  }
}

.loading {
  position: absolute;
  width: 1vw;
  height: 1vw;
  left: 1vw;
  top: -6vw;
}

.loading:before,
.loading:after {
  position: absolute;
  display: inline-block;
  width: 4vw;
  height: 4vw;
  content: "";
  border-radius: 100%;
  background-color: #000;
}

.loading:before {
  left: -1vw;
  animation: ball-pulse infinite 0.75s -0.4s cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.loading:after {
  right: -1vw;
  animation: ball-pulse infinite 0.75s cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

@keyframes ball-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.result-streaming:after {
  -webkit-animation: blink-scale 1s steps(5, start) infinite;
  animation: blink-scale 1s steps(5, start) infinite;
  content: "▋";
  display: inline-block;
  margin-left: 10px;
  vertical-align: baseline;
}
@keyframes blink-scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
</style>
<style lang="scss">
.chartWrap_mobile {
  .md-editor-preview-wrapper {
    /* padding: 3vw !important; */
    padding: 1vw 2vw !important;
    font-size: 3vw !important;
    p {
      font-size: 3vw !important;
      font-family: "siyuan" !important;
      color: #606266 !important;
    }
  }
  .md-editor-preview {
    font-size: 3vw !important;
  }
  blockquote {
    margin-top: 0px !important;
    font-size: 3vw !important;
  }
  blockquote:empty {
    display: none;
  }
  .chart-btn22 {
    /* width: 5vw; */
    /* height: 5vw  !important; */
    border-radius: 1vw !important;
    background: #2774f7;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 8vw !important;
    height: 8vw;
    border: none;
    cursor: pointer;
    .el-icon-top,
    .el-icon-video-pause {
      font-size: 4vw;
      font-weight: bold;
    }
    img {
      width: 3vh;
      height: 3vh;
    }
  }
}
.mobile_device {
  .el-message {
    font-size: 6vw !important;
    .el-message__content {
      font-size: 3vw !important;
    }
  }
}
</style>